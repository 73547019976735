<template>
	<div class="mobile-views-validate-ts-details--component-wrapper">
		<div class="card" v-for="(item, i) in items" :key="i" @click="handleCardClicked(item)">
			<div class="card-body">
				<div class="head">
					<span :class="[`badge badge-${item.validatedClass}`]">
						{{ item.validatedStatus }}
					</span>
				</div>

				<div class="content">
					<!-- {{ item }} -->
					<div class="info">
						<span>
							{{ FormMSG(46, 'Hours') }}: <b>{{ item.hhmm }}</b>
						</span>
						<span>
							{{ FormMSG(47, 'Ovt') }}: <b>{{ item.hhmmOvt }}</b>
						</span>
						<span>
							{{ FormMSG(55, 'Night') }}: <b>{{ item.hhmmNight }}</b>
						</span>
						<span>
							{{ FormMSG(48, 'Anticipated') }}: <b>{{ item.hhmmTooEarly }}</b>
						</span>
					</div>
					<div class="info">
						<span>
							{{ FormMSG(2, 'Time') }}:
							<b>{{ rendStartEndTime(item.strTime, item.endTime) }}</b>
						</span>
						<span>
							{{ FormMSG(1, 'Lunch time') }}:
							<b>{{ rendStartEndTime(item.lunchStrTime, item.lunchEndTime) }}</b>
						</span>
						<span>
							{{ FormMSG(50, 'Rate') }}: <b>{{ item.dayRate }}%</b>
						</span>
						<span>
							{{ FormMSG(49, 'Hotel') }}: <b>{{ rendYesNo(item.hotel) }}</b>
						</span>
						<span>
							{{ FormMSG(54, 'Lunch') }}: <b>{{ rendYesNo(item.lunch) }}</b>
						</span>
					</div>

					<div class="actions">
						<!-- <b-button
              variant="success"
              @click.prevent.stop="validateTsDay"
            >
              <i class="icon-check" />
            </b-button> -->
						<b-button variant="success" v-if="item.validated == 4 || item.validated == 16" @click.prevent.stop="undoRejectTsDay(item)">
							{{ FormMSG(56, 'Undo') }}
							<i class="icon-action-undo"></i>
						</b-button>

						<b-button v-if="item.validated != 4 && item.validated != 16" variant="danger" @click.prevent.stop="rejectTsDay(item)">
							<i class="icon-action-undo" />
						</b-button>
					</div>
				</div>

				<div class="foot">
					<span v-html="rendDate(item.date)" class="date" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { rendCurrency, formatTableDate } from '~helpers';
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'MobileViewsValidateTsDetailsListComponent',
	mixins: [languageMessages],
	props: {
		items: {
			type: Array,
			required: true,
			default: () => []
		}
	},
	methods: {
		handleCardClicked(item) {
			this.$emit('card-clicked', item);
		},
		undoRejectTsDay(item) {
			this.$emit('undo-reject-ts-day', item.id);
		},
		rejectTsDay(item) {
			this.$emit('reject-ts-day', item.id);
		},

		/**
		 * @param {String or Number}
		 * @return {String}
		 */
		rendCurrency(price) {
			return rendCurrency(price);
		},

		/**
		 * @param {String}
		 * @return {String}
		 */
		formatTime(value) {
			return value.split('T')[1].substring(0, 5);
		},

		/**
		 * @param {String}
		 * @return {String}
		 */
		rendDate(date) {
			return formatTableDate(date);
		},

		/**
		 * @param {String}
		 * @return {String}
		 */
		rendYesNo(value) {
			return value == false ? 'N' : 'Y';
		},

		/**
		 * @param {String} start
		 * @param {String} end
		 * @return {String}
		 */
		rendStartEndTime(start, end) {
			return [this.formatTime(start), this.formatTime(end)].join(' - ');
		}
	}
};
</script>

<style lang="scss" scoped>
.head,
.content {
	width: 100%;
}

.head {
	padding-bottom: 10px;
}
.content {
	display: flex;
	justify-content: space-between;

	.info span {
		display: inline-block;
		width: 100%;
	}
	.actions {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		button {
			margin-left: 20px;
		}
	}
}
.foot {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	padding-top: 10px;
}
</style>
