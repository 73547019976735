var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mobile-views-validate-ts-details--component-wrapper" },
    _vm._l(_vm.items, function (item, i) {
      return _c(
        "div",
        {
          key: i,
          staticClass: "card",
          on: {
            click: function ($event) {
              return _vm.handleCardClicked(item)
            },
          },
        },
        [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "head" }, [
              _c("span", { class: [`badge badge-${item.validatedClass}`] }, [
                _vm._v(
                  "\n\t\t\t\t\t\t" +
                    _vm._s(item.validatedStatus) +
                    "\n\t\t\t\t\t"
                ),
              ]),
            ]),
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "info" }, [
                _c("span", [
                  _vm._v(
                    "\n\t\t\t\t\t\t\t" + _vm._s(_vm.FormMSG(46, "Hours")) + ": "
                  ),
                  _c("b", [_vm._v(_vm._s(item.hhmm))]),
                ]),
                _c("span", [
                  _vm._v(
                    "\n\t\t\t\t\t\t\t" + _vm._s(_vm.FormMSG(47, "Ovt")) + ": "
                  ),
                  _c("b", [_vm._v(_vm._s(item.hhmmOvt))]),
                ]),
                _c("span", [
                  _vm._v(
                    "\n\t\t\t\t\t\t\t" + _vm._s(_vm.FormMSG(55, "Night")) + ": "
                  ),
                  _c("b", [_vm._v(_vm._s(item.hhmmNight))]),
                ]),
                _c("span", [
                  _vm._v(
                    "\n\t\t\t\t\t\t\t" +
                      _vm._s(_vm.FormMSG(48, "Anticipated")) +
                      ": "
                  ),
                  _c("b", [_vm._v(_vm._s(item.hhmmTooEarly))]),
                ]),
              ]),
              _c("div", { staticClass: "info" }, [
                _c("span", [
                  _vm._v(
                    "\n\t\t\t\t\t\t\t" +
                      _vm._s(_vm.FormMSG(2, "Time")) +
                      ":\n\t\t\t\t\t\t\t"
                  ),
                  _c("b", [
                    _vm._v(
                      _vm._s(_vm.rendStartEndTime(item.strTime, item.endTime))
                    ),
                  ]),
                ]),
                _c("span", [
                  _vm._v(
                    "\n\t\t\t\t\t\t\t" +
                      _vm._s(_vm.FormMSG(1, "Lunch time")) +
                      ":\n\t\t\t\t\t\t\t"
                  ),
                  _c("b", [
                    _vm._v(
                      _vm._s(
                        _vm.rendStartEndTime(
                          item.lunchStrTime,
                          item.lunchEndTime
                        )
                      )
                    ),
                  ]),
                ]),
                _c("span", [
                  _vm._v(
                    "\n\t\t\t\t\t\t\t" + _vm._s(_vm.FormMSG(50, "Rate")) + ": "
                  ),
                  _c("b", [_vm._v(_vm._s(item.dayRate) + "%")]),
                ]),
                _c("span", [
                  _vm._v(
                    "\n\t\t\t\t\t\t\t" + _vm._s(_vm.FormMSG(49, "Hotel")) + ": "
                  ),
                  _c("b", [_vm._v(_vm._s(_vm.rendYesNo(item.hotel)))]),
                ]),
                _c("span", [
                  _vm._v(
                    "\n\t\t\t\t\t\t\t" + _vm._s(_vm.FormMSG(54, "Lunch")) + ": "
                  ),
                  _c("b", [_vm._v(_vm._s(_vm.rendYesNo(item.lunch)))]),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "actions" },
                [
                  item.validated == 4 || item.validated == 16
                    ? _c(
                        "b-button",
                        {
                          attrs: { variant: "success" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.undoRejectTsDay(item)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(56, "Undo")) +
                              "\n\t\t\t\t\t\t\t"
                          ),
                          _c("i", { staticClass: "icon-action-undo" }),
                        ]
                      )
                    : _vm._e(),
                  item.validated != 4 && item.validated != 16
                    ? _c(
                        "b-button",
                        {
                          attrs: { variant: "danger" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.rejectTsDay(item)
                            },
                          },
                        },
                        [_c("i", { staticClass: "icon-action-undo" })]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "foot" }, [
              _c("span", {
                staticClass: "date",
                domProps: { innerHTML: _vm._s(_vm.rendDate(item.date)) },
              }),
            ]),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }